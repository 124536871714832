<template>
  <div class="account-nav-list">
    <app-menu-button
      v-for="item in items"
      :key="item.id"
      class="account-nav-list__item"
      :label="item.label"
      :icon="item.icon"
      :badge="item.id !== RouteName.AccountBonuses ? item.badge : undefined"
      :badge-color="item.badgeColor"
      :active="item.isActive"
      :to="item.to"
      @click="item.onClick"
    >
      <template v-if="item.id === RouteName.AccountBonuses" #append>
        <app-badge color="secondary">
          <bonus-coin :count="item.badge" />
        </app-badge>
      </template>
    </app-menu-button>
  </div>
</template>

<script lang="ts" setup>
import BonusCoin from 'src/components/bonus/BonusCoin.vue'
import { useAccountNav } from 'src/composables'
import { RouteName } from 'src/types/router'

const { items } = useAccountNav()
</script>

<style lang="scss" scoped>
.account-nav-list {
  --account-nav-list-px: var(--md);
  --account-nav-list-ps: var(--account-nav-list-px);
  --account-nav-list-pe: var(--account-nav-list-px);
  &__item {
    --menu-button-ps: var(--account-nav-list-ps) !important;
    --menu-button-pe: var(--account-nav-list-pe) !important;
  }
}
</style>
