import type { Nullable } from 'src/types'
import { ModelList } from '../modelList'
import { CategoryItem, type CategoryItemData, type CategoryId } from 'src/models/catalog/category'

export type CategoryTreeItemData = CategoryItemData & {
  column: Nullable<number>
  ordering: Nullable<number>
  subcategory: CategoryTreeItemData[]
}

export type CategoryTreeData = CategoryTreeItemData[]

export class CategoryTreeItem extends CategoryItem<CategoryTreeItemData> {
  constructor(data: CategoryTreeItemData) {
    super(data)
  }

  get column() {
    return this.data.column ?? 0
  }

  get position() {
    return this.data.ordering ?? 0
  }

  get subcategories() {
    return new CategoryTree(this.data.subcategory)
  }
}

export class CategoryTree extends ModelList<typeof CategoryTreeItem> {
  constructor(items?: CategoryTreeData) {
    super(CategoryTreeItem, items)
  }

  get items() {
    return [...super.items].sort((a, b) => a.position - b.position)
  }

  get columns() {
    const columns: Record<number, CategoryTreeItem[]> = {}

    for (let item of this.items) {
      const col = item.column
      if (!columns[col]) columns[col] = []
      columns[col].push(item)
    }

    return columns
  }

  get id() {
    return this.items.map(item => item.id).join()
  }

  static findCategoryItem(tree: CategoryTree, id: CategoryId): CategoryTreeItem | undefined {
    if (tree.isEmpty) return undefined

    for (let item of tree.items) {
      if (item.id === id) return item
      const result = CategoryTree.findCategoryItem(item.subcategories, id)
      if (result) return result
    }

    return undefined
  }

  static getAllTreeItems(tree: CategoryTree): CategoryTreeItem[] {
    let items: CategoryTreeItem[] = []

    for (let item of tree.items) {
      items.push(item)

      if (item.subcategories.notEmpty) {
        items = [...items, ...CategoryTree.getAllTreeItems(item.subcategories)]
      }
    }

    return items
  }
}
