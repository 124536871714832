import { type PhoneContact, PhoneContactModel } from 'src/models/contacts/phone'
import { type WorkingHours, WorkingHoursModel } from 'src/models/contacts/workingHours'
import type { ID } from 'src/types'
import type { Opaque } from 'type-fest'

export type SiteContacts = {
  id: Opaque<ID, 'SiteContactsId'>
  title?: string | null
  description?: string | null
  workingHours: WorkingHours[]
  phoneContacts: PhoneContact[]
}

export class SiteContactsModel {
  readonly id: SiteContacts['id']
  readonly title?: string
  readonly description?: string
  readonly workingHours: WorkingHoursModel[]
  readonly phoneContacts: PhoneContactModel[]

  constructor(data: SiteContacts) {
    this.id = data.id
    this.title = data.title ?? undefined
    this.description = data.description ?? undefined
    this.phoneContacts = data.phoneContacts.map(item => new PhoneContactModel(item))
    this.workingHours = data.workingHours.map(item => new WorkingHoursModel(item))
  }
}
