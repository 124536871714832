<template>
  <app-menu-button
    :label="item.name"
    :to="isLink ? itemComputed.to.value : undefined"
    :icon="item.icon"
    :arrow="item.subcategories.notEmpty"
    :active="item.id === selected"
  />
</template>

<script lang="ts" setup>
import type { CategoryId } from 'src/models/catalog/category'
import type { CategoryTreeItem } from 'src/models/catalog/categoryTree'
import { useCategoryItemComputed } from 'src/composables/catalog/category'

export interface Props {
  item: CategoryTreeItem
  selected?: CategoryId
  isLink?: boolean
}

const props = defineProps<Props>()

const itemComputed = useCategoryItemComputed(() => props.item)
</script>

<style lang="scss" scoped>

</style>
