<template>
  <modal-aside
    ref="modalRef"
    class="catalog-modal"
    :head="head"
    :back-button="!!selectedCategory && isCompactView"
    @back="selectedCategoryHistory.undo()"
  >
    <div class="catalog-modal__menu">
      <app-menu-button
        v-if="selectedCategory && isCompactView"
        :to="RouterService.getRouteLocation('category', { slug: selectedCategory.slug, locale })"
        class="catalog-modal__item"
      >
        <template #label>
          {{ `${t('category.all')}: ` }}
          <span class="text-bold">{{ selectedCategory.name }}</span>
        </template>
      </app-menu-button>

      <category-tree-menu-item
        v-for="item in selectedCategoryTree.items"
        :key="item.id"
        :item="item"
        :selected="selectedCategory?.id"
        :is-link="item.subcategories.isEmpty || !isCompactView"
        class="catalog-modal__item"
        @click="onClick(item)"
        @mouseenter="onMouseenter(item)"
      />
    </div>

    <template #outside>
      <transition name="fade">
        <app-content v-if="selectedCategory && !isCompactView" class="catalog-subcategories">
          <div class="catalog-subcategories__head">
            <span>{{ t('category.all') }}:&ensp;</span>
            <category-link :item="selectedCategory" class="text-bold" />
          </div>

          <div class="catalog-subcategories__content">
            <div
              v-for="(column, index) in selectedCategory.subcategories.columns"
              :key="index"
              class="catalog-subcategories__column"
            >
              <div
                v-for="group in column"
                :key="group.id"
                class="catalog-subcategories__group"
              >
                <category-link
                  :item="group"
                  class="catalog-subcategories__group-head text-bold"
                />
                <div v-if="group.subcategories.notEmpty" class="catalog-subcategories__group-items">
                  <category-link
                    v-for="groupItem in group.subcategories.items"
                    :key="groupItem.id"
                    :item="groupItem"
                    class="catalog-subcategories__group-item"
                  />
                </div>
              </div>
            </div>
          </div>
        </app-content>
      </transition>
    </template>
  </modal-aside>
</template>

<script setup lang="ts">
import CategoryLink from 'src/components/catalog/CategoryLink.vue'
import ModalAside from 'src/components/modal/ModalAside.vue'
import CategoryTreeMenuItem from 'src/components/catalog/CategoryTreeMenuItem.vue'
import { useCategoryTree } from 'src/composables/catalog/categoryTree'
import { ref, computed } from 'vue'
import type { CategoryTree } from 'src/models/catalog/categoryTree'
import { type CategoryTreeItem } from 'src/models/catalog/categoryTree'
import { type Ref } from 'vue'
import { useRefHistory } from '@vueuse/core'
import { useQuasar } from 'quasar'
import { useRoute } from 'vue-router'
import type { ModalAsideExpose } from 'src/types/components'
import { watch } from 'vue'
import { useApiSiteGetInfoQuery } from 'src/composables/api/site'
import RouterService from 'src/services/router.service'
import { useI18n } from 'src/composables/useI18n'

const $q = useQuasar()

const { t, locale } = useI18n()

const isCompactView = computed(() => $q.screen.lt.md)

const modalRef = ref<ModalAsideExpose | null>(null)

const route = useRoute()

const siteInfoQuery = useApiSiteGetInfoQuery()

const categoryTree = useCategoryTree(() => siteInfoQuery.data.value?.categories)

const selectedCategory = ref<CategoryTreeItem | undefined>(undefined) as Ref<CategoryTreeItem | undefined>

const selectedCategoryHistory = useRefHistory(selectedCategory)

const selectedCategoryTree = computed<CategoryTree>(() => {
  if (!isCompactView.value || !selectedCategory.value) return categoryTree.value
  return selectedCategory.value.subcategories
})

const head = computed(() => {
  if (isCompactView.value) return selectedCategory.value?.name || t('translate.catalog')
  return t('translate.catalog')
})

const onClick = async (item: CategoryTreeItem) => {
  if (item.subcategories.isEmpty) return
  selectedCategory.value = item
}

const onMouseenter = (item: CategoryTreeItem) => {
  if (!$q.platform.is.desktop || isCompactView.value) return

  if (item.subcategories.isEmpty) {
    selectedCategory.value = undefined
  } else {
    selectedCategory.value = item
  }
}

watch(() => route.fullPath, () => {
  onClose()
})

watch(selectedCategory, () => {
  if (!isCompactView.value) return
  modalRef.value?.scrollTop()
}, { flush: 'post' })

const onClose = () => {
  modalRef.value?.hide()
}
</script>

<style scoped lang="scss">
.catalog-modal {
  &__item {
    --menu-button-px: var(--modal-aside-px) !important;
    margin: 0 calc(var(--modal-aside-px) * -1);
  }
}

.catalog-subcategories {
  position: absolute;
  left: 100%;
  top: 0;
  height: 100%;
  width: calc(100vw - var(--modal-aside-content-width));
  max-width: 900px;
  border-left: 1px solid var(--theme-color-separator);
  overflow: auto;
  &__head {
    height: var(--computed-header-height);
    padding: 0 var(--modal-aside-px);
    display: flex;
    align-items: center;
  }
  &__content {
    padding: var(--modal-aside-pa);
    gap: var(--md);
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
  &__group-head {
    padding: 10px 0;
  }
}
</style>
