import type { Locale, BaseLocale, ExternalLocale } from 'src/types'
import type { RouteLocationRaw } from 'vue-router'
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import RouterService from 'src/services/router.service'
import { EXTERNAL_LOCALES, LOCALES } from 'src/config'
import { useI18n } from 'src/composables/useI18n'

export type LocaleItem = BaseLocale & {
  to?: RouteLocationRaw
  href?: string
}

export function useLocaleSwitcher() {
  const i18n = useI18n()

  const router = useRouter()

  const locales = computed<LocaleItem[]>(() => {
    return [...LOCALES, ...EXTERNAL_LOCALES].map((locale) => {
      const { id, code, label } = locale

      const to = !isExternalLocale(locale)
        ? RouterService.changeRouteLocale(router.currentRoute.value, id)
        : undefined

      const href = isExternalLocale(locale) ? locale.url : undefined

      return {
        id,
        code,
        label,
        to,
        href
      }
    })
  })

  const locale = computed(() => {
    return locales.value.find(item => item.id === i18n.locale.value)
  })

  const options = computed(() => {
    return locales.value.filter(item => item.id !== i18n.locale.value)
  })

  return {
    locale,
    locales,
    options
  }

  function isExternalLocale(locale: Locale | ExternalLocale): locale is ExternalLocale {
    const url: keyof ExternalLocale = 'url'
    return url in locale
  }
}
