<template>
  <div v-if="userInfo" class="account-head">
    <app-avatar
      class="account-head__avatar"
      :name="firstName"
    />
    <div class="account-head__content">
      <div class="text-h6 account-head__message" :title="message">{{ message }}</div>
      <div class="text-muted text-body2 account-head__username" :title="usernameDisplay">{{ usernameDisplay }}</div>
    </div>
  </div>

  <div v-else class="account-head">
    <app-avatar class="account-head__avatar" />
    <div class="account-head__content">
      <app-skeleton type="text" class="text-h6 account-head__message" :style="{ maxWidth: '12em' }" />
      <app-skeleton type="text" class="text-body2" :style="{ maxWidth: '8em' }" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useAccount } from 'src/composables/account'
import { computed } from 'vue'
import { useI18n } from 'src/composables/useI18n'

const { userInfo, firstName, usernameDisplay } = useAccount()

const { t } = useI18n()

const message = computed(() => t('messages.hello', { name: firstName.value }))
</script>

<style lang="scss" scoped>
.account-head {
  display: flex;
  align-items: center;
  gap: 12px;
  &__content {
    flex: 1 1 auto;
    overflow: hidden;
  }
  &__avatar {
    flex: 0 0 auto;
  }
  &__message {
    display: block;
    margin-bottom: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &__username {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>
