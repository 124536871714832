import type { ID } from 'src/types'
import type { Opaque } from 'type-fest'

export type WorkingHours = {
  id: Opaque<ID, 'WorkingHoursId'>
  siteContact: number
  value?: string | null
}

export class WorkingHoursModel implements Omit<WorkingHours, 'siteContact'> {
  id
  value

  constructor(data: WorkingHours) {
    this.id = data.id
    this.value = data.value ?? undefined
  }
}
