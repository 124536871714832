import iconHouse from 'assets/icons/light/house.svg?raw'
import iconCartShopping from 'assets/icons/light/cart-shopping.svg?raw'
import iconBars from 'assets/icons/light/bars.svg?raw'
import iconArrowRightToBracket from 'assets/icons/light/arrow-right-to-bracket.svg?raw'
import iconGrid from 'assets/icons/light/grid-2.svg?raw'
import iconUser from 'assets/icons/light/user.svg?raw'
import RouterService from 'src/services/router.service'
import ModalAsideMenu from 'src/components/modal/ModalAsideMenu.vue'
import AccountMenuModal from 'src/components/account/AccountMenuModal.vue'
import TheCatalogModal from 'src/components/TheCatalogModal.vue'
import { useQuasar } from 'quasar'
import { computed } from 'vue'
import type { NavItem } from 'src/types/nav'
import { useCart } from 'src/composables/cart'
import { RouteName } from 'src/types/router'
import { useStateStore } from 'src/stores/state'
import { useI18n } from 'src/composables/useI18n'
import { useAccount } from './account'

export function useNav() {
  const $q = useQuasar()
  const stateStore = useStateStore()
  const i18n = useI18n()
  const cart = useCart()
  const { isAuth } = useAccount()

  return {
    main: computed<NavItem>(() => ({
      id: RouteName.Main,
      label: i18n.t('translate.mainPage'),
      icon: iconHouse,
      to: RouterService.getRouteLocation(RouteName.Main, { locale: i18n.locale.value })
    })),
    menu: computed<NavItem>(() => ({
      id: 'menu',
      label: i18n.t('translate.menu'),
      icon: iconBars,
      onClick: () => {
        $q.dialog({ component: ModalAsideMenu })
      }
    })),
    cart: computed<NavItem>(() => ({
      id: 'cart',
      label: i18n.t('translate.cart'),
      icon: iconCartShopping,
      badge: cart.model.value?.totalItems ?? 0,
      loading: cart.query.isFetching.value,
      onClick: () => {
        stateStore.showCart()
      }
    })),
    account: computed<NavItem>(() => ({
      id: 'account',
      label: isAuth.value ? i18n.t('translate.account') : i18n.t('actions.login'),
      icon: !isAuth.value ? iconArrowRightToBracket : iconUser,
      loading: stateStore.isPendingLogin,
      onClick: () => {
        if (isAuth.value) {
          $q.dialog({ component: AccountMenuModal })
        } else {
          stateStore.showAuthModal = true
        }
      }
    })),
    catalog: computed<NavItem>(() => ({
      id: 'catalog',
      label: i18n.t('translate.catalog'),
      icon: iconGrid,
      onClick: () => {
        $q.dialog({ component: TheCatalogModal })
      }
    }))
  }
}
