import iconBagsShopping from 'assets/icons/light/bags-shopping.svg?raw'
import iconHeart from 'assets/icons/light/heart.svg?raw'
import iconArrowsToEye from 'assets/icons/light/arrows-to-eye.svg?raw'
import iconCircleDollarToSlot from 'assets/icons/light/circle-dollar-to-slot.svg?raw'
import iconMessagesQuestion from 'assets/icons/light/messages-question.svg?raw'
import iconAddressCard from 'assets/icons/light/address-card.svg?raw'
import RouterService from 'src/services/router.service'
import { useStateStore } from 'src/stores/state'
import { useAccount } from 'src/composables/account'
import { reactiveComputed } from '@vueuse/core'
import { useRoute } from 'vue-router'
import { RouteName } from 'src/types/router'
import type { NavItem } from 'src/types/nav'
import { computed } from 'vue'
import { useI18n } from 'src/composables/useI18n'

export function useAccountNav() {
  const stateStore = useStateStore()
  const { t } = useI18n()
  const route = useRoute()
  const account = useAccount()

  const { locale } = useI18n()

  const orders = computed<NavItem>(() => ({
    id: RouteName.AccountOrders,
    label: t('translate.orders'),
    icon: iconBagsShopping,
    to: RouterService.getRouteLocation(RouteName.AccountOrders, { locale: locale.value }),
    isActive: route.name === RouteName.AccountOrders || route.name === RouteName.AccountOrder
  }))

  const favourites = computed<NavItem>(() => ({
    id: RouteName.AccountFavourites,
    label: t('translate.wishList'),
    icon: iconHeart,
    badge: account.wishlistProducts.value?.length || undefined,
    to: account.isAuth.value
      ? RouterService.getRouteLocation(RouteName.AccountFavourites, { locale: locale.value })
      : undefined,
    isActive: route.name === RouteName.AccountFavourites,
    onClick: () => {
      if (account.isAuth.value) return
      stateStore.showAuthModal = true
    }
  }))

  const viewedProducts = computed<NavItem>(() => ({
    id: RouteName.AccountViewedProducts,
    label: t('translate.viewedProducts'),
    icon: iconArrowsToEye,
    badge: account.viewedProducts.value?.length || undefined,
    to: RouterService.getRouteLocation(RouteName.AccountViewedProducts, { locale: locale.value }),
    isActive: route.name === RouteName.AccountViewedProducts
  }))

  const bonuses = computed<NavItem>(() => ({
    id: RouteName.AccountBonuses,
    label: t('translate.bonuses'),
    icon: iconCircleDollarToSlot,
    to: RouterService.getRouteLocation(RouteName.AccountBonuses, { locale: locale.value }),
    isActive: route.name === RouteName.AccountBonuses,
    badge: account.bonuses.value?.total
  }))

  const feedbackAndQuestions = computed<NavItem>(() => ({
    id: RouteName.AccountFeedbackAndQuestions,
    label: t('translate.feedbackAndQuestions'),
    icon: iconMessagesQuestion,
    to: RouterService.getRouteLocation(RouteName.AccountFeedbackAndQuestions, { locale: locale.value }),
    isActive: route.name === RouteName.AccountFeedbackAndQuestions
  }))

  const profile = computed<NavItem>(() => ({
    id: RouteName.AccountProfile,
    label: t('translate.personalData'),
    icon: iconAddressCard,
    to: RouterService.getRouteLocation(RouteName.AccountProfile, { locale: locale.value }),
    isActive: route.name === RouteName.AccountProfile
  }))

  const items = reactiveComputed(() => [
    orders.value,
    favourites.value,
    viewedProducts.value,
    bonuses.value,
    feedbackAndQuestions.value,
    profile.value
  ])

  return {
    favourites,
    items
  }
}
