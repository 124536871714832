<template>
  <div class="account-nav">
    <account-head class="account-nav__head" />

    <account-nav-list class="account-nav__list" />

    <app-button
      class="account-nav__logout"
      color="dark"
      :label="t('actions.logout')"
      :icon="iconArrowLeftFromBracket"
      @click="onLogout"
    />
  </div>
</template>

<script lang="ts" setup>
import iconArrowLeftFromBracket from 'assets/icons/light/arrow-left-from-bracket.svg?raw'
import AccountHead from 'src/components/account/AccountHead.vue'
import AccountNavList from 'src/components/account/AccountNavList.vue'
import { useLogout, useI18n } from 'src/composables'

const emit = defineEmits<{
  logout: []
}>()

const { t } = useI18n()

const logout = useLogout()

function onLogout() {
  logout()
  emit('logout')
}
</script>

<style lang="scss" scoped>
.account-nav {
  --account-nav-px: var(--md);
  --account-nav-ps: var(--account-nav-px);
  --account-nav-pe: var(--account-nav-px);
  display: flex;
  flex-direction: column;
  &__head {
    margin-bottom: var(--md);
    flex: 0 0 auto;
  }
  &__list {
    --account-nav-list-ps: calc(var(--account-nav-ps) + 4px) !important;
    --account-nav-list-pe: calc(var(--account-nav-pe) + 4px) !important;
    flex: 0 0 auto;
    margin: 0 calc(var(--account-nav-pe) * -1) var(--md) calc(var(--account-nav-ps) * -1);
  }
  &__logout {
    flex: 0 0 auto;
  }
  @media (max-width: $breakpoint-xs-max) {
    &__list {
      flex: 1 0 auto;
    }
  }
}
</style>
