<template>
  <div class="contacts">
    <div class="working-hours q-mb-sm">
      <div class="working-hours__list">
        <div
          v-for="item in model.workingHours"
          :key="item.id"
          class="text-muted text-caption"
        >
          {{ item.value }}
        </div>
      </div>
    </div>

    <div class="phones">
      <div class="phones__list q-mb-sm">
        <base-phone-contact
          v-for="item in model.phoneContacts"
          :key="item.id"
          :phone="item.phone"
          :icon="item.icon"
          class="phones__item"
        />
      </div>
    </div>

    <div v-if="model.description" class="phones__description text-caption text-muted">
      {{ model.description }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import BasePhoneContact from 'src/components/base/BasePhoneContact.vue'
import { type SiteContacts, SiteContactsModel } from 'src/models/site/contacts'
import { computed } from 'vue'

const props = defineProps<SiteContacts>()

const model = computed(() => new SiteContactsModel(props))
</script>

<style lang="scss" scoped>
.phones {
  &__list {
    display: grid;
    grid-template-columns: 1fr;
    gap: var(--xs) var(--grid-gutter);
  }
  @media (max-width: $breakpoint-sm-max) {
    &__list {
      grid-template-columns: 1fr 1fr;
      gap: var(--sm) var(--grid-gutter);
    }
  }
}
</style>
