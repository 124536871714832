<template>
  <app-link
    :to="itemComputed.to.value"
    color="theme"
    class="category-link"
  >
    <span class="category-link__name">{{ item.name }}</span>
  </app-link>
</template>

<script lang="ts" setup>
import type { CategoryItem } from 'src/models/catalog/category'
import { useCategoryItemComputed } from 'src/composables/catalog/category'

export interface Props {
  item: CategoryItem
}

const props = defineProps<Props>()

const itemComputed = useCategoryItemComputed(() => props.item)
</script>

<style lang="scss" scoped>

</style>
