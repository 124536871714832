<template>
  <modal-aside
    ref="dialogRef"
    class="menu"
    position="left"
    :head="t('translate.menu')"
  >
    <div class="menu-group">
      <app-menu-button
        class="menu__item"
        :icon="nav.main.value.icon"
        :label="nav.main.value.label"
        :to="nav.main.value.to"
      />

      <app-menu-button
        class="menu__item"
        :icon="nav.catalog.value.icon"
        :label="nav.catalog.value.label"
        @click="nav.catalog.value.onClick"
      />
    </div>

    <div class="menu-group">
      <app-menu-button
        v-for="item in navGroup"
        :key="item.id"
        :icon="item.icon"
        :label="item.label"
        :to="item.to"
        :badge="item.badge"
        class="menu__item"
        @click="item.onClick"
      />
    </div>

    <div v-if="submenu.notEmpty.value" class="menu-group">
      <h5>{{ t('translate.shop') }}</h5>

      <app-menu-button
        v-for="item in submenu.items.value"
        :key="item.url"
        :label="item.name"
        :to="item.url"
        class="menu__item"
      />
    </div>

    <div class="menu-group">
      <site-contacts
        v-if="siteInfoQuery.data.value?.contacts"
        v-bind="siteInfoQuery.data.value.contacts"
      />
    </div>

    <div class="menu-group">
      <locale-list />
    </div>
  </modal-aside>
</template>

<script setup lang="ts">
import SiteContacts from 'src/components/SiteContacts.vue'
import LocaleList from 'src/components/locale/LocaleList.vue'
import ModalAside from 'src/components/modal/ModalAside.vue'
import { computed } from 'vue'
import { useNav } from 'src/composables/nav'
import { useAccountNav, useApiSiteGetInfoQuery, useI18n } from 'src/composables'
import { useSubmenu } from 'src/composables/menu/submenu'

const { t } = useI18n()

const nav = useNav()

const accountNav = useAccountNav()

const navGroup = computed(() => [
  nav.account.value,
  nav.cart.value,
  accountNav.favourites.value
])

const siteInfoQuery = useApiSiteGetInfoQuery()

const submenu = useSubmenu(() => siteInfoQuery.data.value?.submenu)
</script>

<style scoped lang="scss">
.menu {
  --menu-px: var(--modal-aside-px);
  --menu-ps: var(--menu-px);
  --menu-pe: calc(var(--menu-px) + 11px);

  &__item {
    --menu-button-ps: var(--menu-ps);
    --menu-button-pe: var(--menu-pe);
    margin: 0 calc(var(--menu-px) * -1);
  }

  &__submenu {
    --submenu-px: var(--menu-px) !important;
    margin: 0 calc(var(--submenu-px) * -1);
  }
}

.menu-group {
  &:not(:last-child) {
    margin-bottom: var(--md);
    padding-bottom: var(--md);
    border-bottom: 1px solid var(--theme-color-separator);
    transition: border-bottom var(--trs-1);
  }
}
</style>
