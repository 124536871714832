
<template>
  <div class="phone-contact">
    <span v-if="mobileOperator" class="phone-contact__mobile-operator text-muted text-caption">
      {{ `${mobileOperator}: ` }}
    </span>

    <app-link
      class="phone-contact__link"
      :icon="iconComputed"
      :href="href"
    >
      {{ formattedPhone }}
    </app-link>
  </div>
</template>

<script lang="ts" setup>
import iconPhone from 'assets/icons/light/phone.svg?raw'
import { formatPhoneNumber, getClearPhone } from 'src/utils/strings'
import { computed } from 'vue'

const props = defineProps<{
  phone: string
  icon?: string
  mobileOperator?: string
}>()

const iconComputed = computed(() => props.icon ?? iconPhone)

const clearPhone = computed(() => getClearPhone(props.phone))

const formattedPhone = computed(() => formatPhoneNumber(props.phone))

const href = computed(() => `tel: ${clearPhone.value}`)
</script>

<style lang="scss" scoped>
.phone-contact {
  display: inline-flex;
  align-items: center;
  gap: 0 var(--sm);
}
</style>
