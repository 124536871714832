import type { ID } from 'src/types'
import { formatPhoneNumber, getClearPhone } from 'src/utils/strings'
import type { Opaque } from 'type-fest'

export type PhoneContact = {
  id: Opaque<ID, 'PhoneContactId'>
  siteContact: number
  phone: string
  icon?: string | null
  mobileOperator?: string | null
}

export class PhoneContactModel {
  readonly id: PhoneContact['id']
  readonly phone: string
  readonly phoneDisplay: string
  readonly icon?: string
  readonly mobileOperator?: string

  constructor(data: PhoneContact) {
    this.id = data.id
    this.icon = data.icon ?? undefined
    this.phone = getClearPhone(data.phone)
    this.phoneDisplay = formatPhoneNumber(this.phone)
    this.mobileOperator = data.mobileOperator ?? undefined
  }

  get href() {
    return `tel:${this.phone}`
  }
}
