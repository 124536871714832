<template>
  <modal-aside
    ref="dialogRef"
    class="account-modal"
    position="right"
    :head="t('translate.account')"
  >
    <div class="account-modal__inner">
      <account-nav class="account-modal__account-nav" @logout="onLogout" />
    </div>
  </modal-aside>
</template>

<script setup lang="ts">
import AccountNav from 'src/components/account/AccountNav.vue'
import ModalAside from 'src/components/modal/ModalAside.vue'
import { ref } from 'vue'
import type { ModalAsideExpose } from 'src/types/components'
import { useI18n } from 'src/composables/useI18n'

const { t } = useI18n()

const dialogRef = ref<ModalAsideExpose | null>(null)

function onLogout() {
  dialogRef.value?.hide()
}
</script>

<style scoped lang="scss">
.account-modal {
  &__inner {
    position: relative;
  }
  &__account-nav {
    --account-nav-px: var(--modal-aside-px) !important;
  }
}
</style>
