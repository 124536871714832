import type { Submenu } from 'src/models/menu/submenu'
import { computed, toValue, type MaybeRefOrGetter } from 'vue'
import { useList } from 'src/composables/list'
import { useNormalizeUrl } from 'src/composables/utils'

export type SubmenuItemComputed = {
  name: string
  url: string
}

export function useSubmenu(data: MaybeRefOrGetter<Submenu | undefined>) {
  const normalizeUrl = useNormalizeUrl()

  const items = computed(() => {
    return (toValue(data) ?? []).map((item) => ({
      name: item.name,
      url: item.url ? normalizeUrl(item.url) : undefined,
      priority: item.priority ?? 0
    }))
    .filter((item): item is (SubmenuItemComputed & { priority: number }) => !!item.name && !!item.url)
    .sort((a, b) => a.priority - b.priority)
    .map(({ name, url }) => ({ name, url }))
  })

  return useList(items)
}
