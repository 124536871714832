<template>
  <app-button
    :label="locale.label"
    :color="color"
    :outline="outline"
    :rounded="rounded"
    :size="size"
    :right-angle="rightAngle"
    :full-width="fullWidth"
    v-bind="linkProps"
    caps
    class="locale-button"
  >
    <template v-if="locale.id === 'uk' && !noFlag" #prepend>
      <div class="locale-button__flag">
        <uk-flag />
      </div>
    </template>

    <slot />
  </app-button>
</template>

<script lang="ts" setup>
import UkFlag from 'assets/img/flags/uk.svg?component'
import type { LocaleButtonSize } from 'src/types/components'
import { type LocaleItem } from 'src/composables/useLocaleSwitcher'
import { computed } from 'vue'

interface Props {
  locale: LocaleItem,
  noLink?: boolean
  rounded?: boolean,
  size?: LocaleButtonSize,
  active?: boolean
  rightAngle?: boolean
  fullWidth?: boolean
  outline?: boolean
  noFlag?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  noFlag: false,
  noLink: false
})

const outline = computed(() => props.outline && !props.active)

const color = computed(() => props.active ? 'primary' : 'light')

const linkProps = computed(() => {
  const { noLink } = props
  const { to, href } = props.locale

  if (noLink) return {}

  if (to) return { to }

  if (href) return { href }

  return {}
})
</script>

<style lang="scss" scoped>
.locale-button {
  &__flag {
    display: block;
    width: var(--icon-size);
    height: var(--icon-size);
    position: relative;
    border-radius: 50%;
    overflow: hidden;

    svg {
      position: absolute;
      display: block;
      height: 100%;
      width: auto;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
    }
  }
}
</style>
