import type { CategoryItem } from 'src/models/catalog/category'
import RouterService from 'src/services/router.service'
import type { MaybeRefOrGetter } from 'vue'
import { computed, toValue } from 'vue'
import type { RouteLocationRaw } from 'vue-router'
import { useNormalizeUrl } from 'src/composables/utils'
import { useI18n } from 'src/composables/useI18n'

export function useCategoryItemComputed(model: MaybeRefOrGetter<CategoryItem>) {
  const normalizeUrl = useNormalizeUrl()
  const { locale } = useI18n()

  const to = computed<RouteLocationRaw>(() => {
    const { slug, url } = toValue(model)
    if (url) return normalizeUrl(url)
    return RouterService.getRouteLocation('category', { slug, locale: locale.value })
  })

  return { to }
}
