<template>
  <div class="locale-list">
    <locale-button
      v-for="item in locales"
      :key="item.id"
      :locale="item"
      size="sm"
      outline
      :active="item.id === locale?.id"
      :to="item.to"
      class="locale-list__item"
    />
  </div>
</template>

<script lang="ts" setup>
import LocaleButton from 'src/components/locale/LocaleButton.vue'
import { useLocaleSwitcher } from 'src/composables/useLocaleSwitcher'

const { locale, locales } = useLocaleSwitcher()
</script>

<style lang="scss" scoped>
.locale-list {
  display: flex;
  flex-wrap: wrap;
  gap: var(--sm);
}
</style>
